<template>
<div class="contain">
  名称：
  <el-input size="mini" v-model="pageParams.name" style="width:130px;" placeholder="请输入字典名称" class="EquipmentMarginLeft"></el-input>
  <el-button size="mini" type="primary" style="margin-left:20px;" icon="el-icon-search" @click="search">查询</el-button>
  <el-button type="primary" size="mini" icon="el-icon-refresh" @click="recet">重置</el-button>
  <el-button size="mini" type="success" style="margin-left:20px;" icon="el-icon-plus" @click="addDIC(0,1)">添加一级字典</el-button>
  <el-table
      :data="tableData"
      style="width: 100%;margin: 10px 0;"
      class="table-no-search"
      row-key="id"
      border
      :default-expand-all="false"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      stripe
      :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,background:'#f8f8f9'}">
      
    <el-table-column prop="name" label="字典名称" align="center"></el-table-column>
    <el-table-column prop="id" label="ID" align="center"></el-table-column>
    <el-table-column prop="englishName" label="字典英文名称" align="center"></el-table-column>
    <el-table-column prop="sorting" label="字典排序" align="center"></el-table-column>
    <el-table-column prop="code" label="字典编码" align="center"></el-table-column>
    <el-table-column prop="value" label="字典值" align="center"></el-table-column>
    <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
    <el-table-column label="操作" align="left">
      <template slot-scope="{row}">
        <el-button type="text" v-if="row.parentId!=0" @click="updataDIC(row)" size="small">编辑</el-button>
        <el-button type="text" v-if="row.parentId==0" @click="addDIC(row.id,row.platform)" size="small" style="color:#67C23A">新增子字典</el-button>
        <el-button type="text" v-if="row.isDelete!=1&&row.parentId!=0" @click="del(row)" size="small" style="color:red">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog
      :title="updatasonStatus==0?'新增字典信息':'修改字典信息'"
      :visible.sync="sondialogVisible"
      width="35%"
      :close-on-click-modal="false"
      @close="clearnsonF('sonform')">
    <el-form ref="sonform" :model="sonform" label-width="130px" :rules="rule">
        <el-form-item label="字典名称：" prop="name">
          <el-input v-model="sonform.name"></el-input>
        </el-form-item>
        <el-form-item label="字典值：" prop="value">
          <el-input v-model="sonform.value"></el-input>
        </el-form-item>
      <el-form-item label="字典英文名称：" prop="englishName">
          <el-input v-model="sonform.englishName"></el-input>
        </el-form-item>
        <el-form-item label="字典排序：" prop="sorting">
          <el-input v-model="sonform.sorting"  type="number"></el-input>
        </el-form-item>
        <el-form-item label="字典编码：" prop="code">
          <el-input v-model="sonform.code" placeholder="编码示例：“xxx:yyy”"></el-input>
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="sondialogVisible = false">取 消</el-button>
    <el-button v-if="updatasonStatus==0" type="primary" @click="submitsonfrom('sonform')">确 定</el-button>
    <el-button v-else type="primary" @click="updatasubmitsonfrom('sonform')">确 定</el-button>
  </span>
  </el-dialog>
</div>
</template>

<script>
import {dicpage,dicsave,dicupdata,dicdelete} from "../../RequestPort/Login/LoginRequest";
export default {
  name: "dic",
  data(){
    return{
      pageParams:{
        name:''
      },
      sondialogVisible:false,
      tableData:[{}],
      sonform:{
        platform:2,
        name:'',
        sorting:'',
        code:'',
        description:''
      },
      updatasonStatus:0,
      rule:{
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        sorting: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        code: [{ required: true, message: '请输入编码', trigger: 'blur' }],
        englishName: [{ required: true, message: '请输入英文名称', trigger: 'blur' }],
        value: [{ required: true, message: '请输入值', trigger: 'blur' }],
      }
    }
  },
  mounted() {
    this.loadlist(this.pageParams)
  },
  methods:{
    loadlist(obj){
      dicpage(obj).then(res=>{
       this.tableData = res.data
      })
    },
    recet(){
      this.pageParams.name=''
      this.loadlist(this.pageParams)
    },
    clearnsonF(sonform){
      this.sonform = {
        platform:2,
        name:'',
        sorting:'',
        code:'',
      },
      this.$refs[sonform].resetFields()
    },
    search(){
      this.pageParams.current = 1
      this.loadlist(this.pageParams)
    },
    updataDIC(row){
      console.log(row)
      this.updatasonStatus = 1
      this.sonform = JSON.parse(JSON.stringify(row))
      this.sondialogVisible = !this.sondialogVisible
    },
    addDIC(id,platform){
      this.sonform.pid = id
      this.sonform.platform = platform
      this.updatasonStatus = 0
      this.sondialogVisible = !this.sondialogVisible
    },
    submitsonfrom(sonform){
      this.$refs[sonform].validate((valid) => {
        if (!valid) return false
        console.log(this.sonform)
        dicsave(this.sonform).then(res=>{
          if(res.code===200){
            this.$message.success(res.msg)
            this.sondialogVisible = !this.sondialogVisible
            this.loadlist()
          }else{
            this.$message.error(res.msg)
          }
        })
      })
    },
    updatasubmitsonfrom(sonform){
      this.$refs[sonform].validate((valid) => {
        if (!valid) return false
        dicupdata(this.sonform).then(res=>{
          if(res.code===200){
            this.$message.success(res.msg)
            this.sondialogVisible = !this.sondialogVisible
            this.loadlist()
          }else{
            this.$message.error(res.msg)
          }
        })
      })
    },
    del(row){
      this.$confirm(`是否删除字典---[ ${row.name} ]?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        dicdelete({id:row.id}).then((e)=>{
          if(e.code===200){
            this.$message.success(e.msg)
            this.loadlist()
          }else{
            this.$message.error(e.msg)
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
